import React, { Profiler } from "react";
import { Route,BrowserRouter, Routes } from "react-router-dom";
import Home from "./compnent/Home/Home";
import New from "./compnent/New/New";
import Single from "./compnent/Singel/single ";
import New_Project from "./compnent/New_Project/New_Project";
import Order from "./compnent/order/order";
import { useState } from "react";
import './App.css';
import Services from "./compnent/Services/Services";
import Project from "./compnent/Project/Project";
import About from "./compnent/About/About";
import Questions from "./compnent/Questions/Questions";
import New_Questions from "./compnent/New_Question/New_Question";
import ContactUS from "./compnent/ContactUS/ContactUS";
import Job from "./compnent/Job/Job";
import Media from "./compnent/Media/Media";
import Slider from "./compnent/Slider/Slider";
import Slider_Table from "./compnent/Slider_Table/Slider_Table";
import EditService from "./compnent/EditServices/EditServices";
import EditProject from "./compnent/EditProject/EditProject";
import EditQuestions from "./compnent/EditQuestions/EditQuestions";
import EditSlider from "./compnent/EditSlider/EditSlider";
import Admin from "./compnent/Admin/Admin";
import Profile from "./compnent/Profile/Profile";
import HeaderAbout from "./compnent/HeaderAbout/HeaderAbout";
import AddHeaderAbout from "./compnent/AddHeaderAbout/AddHeaderAbout";
import EditHeaderAbout from "./compnent/EditHeaderAbout/EditHeaderAbout";


const App=()=>{
    const [color,Setcolor]=useState(true);
    const [side,Setside]=useState(true);
    const handel_color=()=>{
        Setcolor(!color)
    }
    const handel_side=()=>{
        const body=document.getElementById('body');
        const s= document.getElementById('side_bar');
        const close=document.getElementById('close')
       if(side==true){
            s.style='display: block;width:250px;backgroundColor:red;position: fixed';
            body.classList.add('body_active');
            close.style='display: block';
       }
       else{
            s.style='display: none';
            body.classList.remove('body_active')
            close.style='display: block';
       }
       Setside(!side)

    }
    return(
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" exact element={<Home color={color} handel_color={handel_color} side={side} handel_side={handel_side}/>}/>
                    <Route path="/Services" element={<Services color={color} handel_color={handel_color} handel_side={handel_side}/>}/>
                    <Route path="/Project" element={<Project color={color} handel_color={handel_color} handel_side={handel_side}/>}/>
                    {/* <Route path="/profile" element={<Single color={color} handel_color={handel_color} handel_side={handel_side}/>}/> */}
                    <Route path="new" element={<New color={color} handel_color={handel_color}  handel_side={handel_side}/>}/>
                    <Route path="/New_Project" element={<New_Project color={color} handel_color={handel_color} handel_side={handel_side}/>}/>
                    <Route path="/Questions" element={<Questions color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/About" element={<About color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/New_Questions" element={<New_Questions color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/ContactUS" element={<ContactUS color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Job" element={<Job color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Media" element={<Media color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Slider" element={<Slider color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Slider_Home" element={<Slider_Table color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Edit-Service/:id" element={<EditService color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Edit-Project/:id" element={<EditProject color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Edit-Questions/:id" element={<EditQuestions color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Edit-Slider/:id" element={<EditSlider color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Admin" element={<Admin color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Profile" element={<Profile color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/HeaderAbout" element={<HeaderAbout color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/AddHeaderAbout" element={<AddHeaderAbout color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    <Route path="/Edit-Header/:id" element={<EditHeaderAbout color={color} handel_color={handel_color} handel_side={handel_side}/>}></Route>
                    
                </Routes>
            </BrowserRouter>
        </div>

    )   
}
export default App;