import React from "react";
import Nav from "./../nav_bar/Nav_bar";
import Site from "./../side_bar/side_bar";
import "./New.css";
import {VscEye} from "react-icons/vsc";
import {ImUpload} from "react-icons/im";
import { useEffect, useState } from "react";
import http from "../../axios/http";

import avatar from "./avtart.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import CheckAdmin from "../CheckAdmin";

const New=(props)=>{
    
    let navigate = useNavigate();
    CheckAdmin()===false ? navigate('/Admin') : console.log();
    const [file, setfile] = useState([]);
    const [Head, setHead] = useState('');
    const [Text, setText] = useState('');
    const [Head_ar, setHead_ar] = useState('');
    const [Text_ar, setText_ar] = useState('');
    const [Price, setPrice] = useState('');


    const handleSubmit = (event) => {
        event.preventDefault();
        // document.getElementById('sent').disabled=true;
        http.post('Services/Store',{
            'image[]':file,
            'head':Head,
            'text':Text,
            'head_ar':Head_ar,
            'text_ar':Text_ar,
            'price':Price
        }).then(
            res=>{
                
                res.status===200 ? toast.success("New Service added 👍",{autoClose: 2000,}) : toast.error("ERROR ",{autoClose: 2000,})
                setHead('');
                setText('');
                setHead_ar('');
                setText_ar('');
                setfile(false);
                setPrice('')
            }
        ).catch(error=>{
            console.log(error);
            
        })
        // ... do something with email
    }
    return(
        <div className={`new ${props.color ===false ? 'dark' :''}`}>
                    <ToastContainer />

            <Site color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
            <div className="contener" id="body">
                <Nav color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
                <div className="top">
                    <h1>add new Services</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="right">
                        <form  onSubmit={handleSubmit}>
                            <div className="form">
                                <div className="input_form">
                                    <label htmlFor="file" className="label_file"> image: <ImUpload className="upload"/></label>
                                    <input type="file" multiple required  id="file" onChange={e=>setfile(e.target.files)} style={{display:"none"}}/>
                                </div>
                                <div className="inputs">
                                
                                <div className="input_form Head">
                                    <label>Head</label>
                                    <input type="text" required name="head" value={Head} onChange={e=>setHead(e.target.value)} placeholder="Head"/>
                                        <br/>
                                        <br/>
                                    <label>price</label>

                                    <input type="number" required name="price" value={Price} onChange={e=>setPrice(e.target.value)} placeholder="price"/>
                                
                                </div>

                                <div className="input_form Head ar">
                                    <label>Head Ar</label>
                                    <input type="text" required value={Head_ar} name="head_ar" onChange={e=>setHead_ar(e.target.value)} placeholder="Head"/>
                                </div>

                                <div className="input_form">
                                    <label>Text</label>
                                    <textarea type="text" value={Text} required name="text" onChange={e=>setText(e.target.value)}  placeholder="Text"/>
                                </div>

                                <div className="input_form ar">
                                    <label>Text Ar</label>
                                    <textarea type="text" value={Text_ar} required name="text_ar" onChange={e=>setText_ar(e.target.value)}  placeholder="Text"/>
                                </div>
                            </div>
                                <div className="button">

                                <button id="sent">sent</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default New;