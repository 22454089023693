import React, { useState, useEffect } from 'react';  
import Nav_bar from "../nav_bar/Nav_bar";  
import { useNavigate } from 'react-router-dom';  
import CheckAdmin from '../CheckAdmin';  
import { useForm } from "react-hook-form";  
import https from '../../axios/http';  
import { ToastContainer, toast } from 'react-toastify';  
import './Profile.css';  
import { FaEye } from 'react-icons/fa';  
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';  
  
const Profile = () => {  
  const navigate = useNavigate();  
  const [data, setData] = useState([]);  
  const [dataError, setDataError] = useState('');  
  const [showPassword, setShowPassword] = useState(false);  
  const [email, setEmail] = useState('');  
  const [name, setName] = useState('');  
  const [password, setPassword] = useState('');  
  const [open, setOpen] = useState(false);  
  const [deleteId, setDeleteId] = useState(null);  
  
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();  
  
  useEffect(() => {  
   if (!CheckAdmin()) {  
    navigate('/Admin');  
   }  
  
   https.get('Admin/Users')  
    .then(res => {  
      setData(res.data);  
    })  
    .catch(error => {  
      console.error(error);  
    });  
  }, []);  
  
  const handlePasswordVisibility = () => {  
   setShowPassword(!showPassword);  
  };  
  
  const handleLogin = async (data) => {  
   try {  
    const response = await https.post('Admin/ChangeData', data);  
    if (response.status === 200) {  
      toast.success("Admin Data Edited", { autoClose: 2000 });  
      setValue('CurrentEmail', '');  
      setValue('CurrentPassword', '');  
      setValue('NewEmail', '');  
      setValue('NewPassword', '');  
    } else {  
      toast.error("Error", { autoClose: 2000 });  
    }  
   } catch (error) {  
    if (error.response.status === 401) {  
      setDataError('Please check your data and try again');  
    }  
    console.error(error);  
   }  
  };  
  
  const handleRegister = async (event) => {  
   event.preventDefault();  
   try {  
    const response = await https.post('Admin/Register', {  
      name,  
      email,  
      password  
    });  
    if (response.status === 200) {  
      toast.success("New Admin Created", { autoClose: 2000 });  
  
      setName('');  
      setEmail('');  
      setPassword('');  
    } else {  
      toast.error("Error", { autoClose: 2000 });  
    }  
   } catch (error) {  
    if (error.response.status === 401) {  
      setDataError('Please check your data and try again');  
    }  
    console.error(error);  
   }  
  };  
  
  const handleDelete = (id) => {  
   setDeleteId(id);  
   setOpen(true);  
  };  
  
  const handleConfirmDelete = async () => {  
   try {  
    const response = await https.post(`Admin/DeleteUser`,{
        id:deleteId
    });  
    if (response.status === 200) {  
      toast.success("User deleted successfully", { autoClose: 2000 });  
      setData(data.filter(item => item.id !== deleteId));  
    } else {  
      toast.error("Error deleting user", { autoClose: 2000 });  
    }  
   } catch (error) {  
    console.error(error);  
   }  
   setOpen(false);  
  };  
  
  const handleCancelDelete = () => {  
   setOpen(false);  
  };  
  
  return (  
   <div className="Profile">  
    <ToastContainer />  
    <Nav_bar />  
    <br />  
    <br />  
    <div className="main-form">  
      <div className="main">  
    <h1>Change Email And Password</h1>  

       <form onSubmit={handleSubmit(handleLogin)}>  
        <div className="form">  
          <div className="input_form">  
           <label htmlFor="email">Current Email</label>  
           <input  
            type="email"  
            name="CurrentEmail"  
            placeholder="Current Email"  
            {...register('CurrentEmail', {  
              required: 'Current Email is Required',  
              pattern: {  
               value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  
               message: "Invalid email address"  
              }  
            })}  
           />  
           {errors.CurrentEmail && <p className="error">{errors.CurrentEmail.message}</p>}  
          </div>  
          <div className="input_form password">  
           <label htmlFor="password">Current Password</label>  
           <input  
            type={showPassword ? 'text' : "password"}  
            autoComplete="new-password"  
            name="CurrentPassword"  
            placeholder="Current Password"  
            {...register('CurrentPassword', {  
              required: 'Current Password is Required',  
              minLength: 8  
            })}  
           />  
           {errors.CurrentPassword && <p className="error">{errors.CurrentPassword.message}</p>}  
           {errors.CurrentPassword?.type === "minLength" && (<p className="error" role="alert">min Length 8 characters </p>)}  
           <FaEye onClick={handlePasswordVisibility} />  
          </div>  
          {dataError && <p className="error_email">{dataError}</p>}  
          <h3>New Data</h3>  
          <div className="input_form">  
           <label htmlFor="email">New Email</label>  
           <input  
            type="email"  
            name="NewEmail"  
            placeholder="New Email"  
            {...register('NewEmail', {  
              required: 'New Email is Required',  
              pattern: {  
               value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  
               message: "Invalid email address"  
              }  
            })}  
           />  
           {errors.NewEmail && <p className="error">{errors.NewEmail.message}</p>}  
          </div>  
          <div className="input_form password">  
           <label htmlFor="password">New Password</label>  
           <input  
            type={showPassword ? 'text' : "password"}  
            min={8}  
            autoComplete="new-password"  
            name="NewPassword"  
            placeholder="New Password"  
            {...register('NewPassword', {  
              required: 'New Password is Required',  
              minLength: 8  
            })}  
           />  
           {errors.NewPassword && <p className="error">{errors.NewPassword.message}</p>}  
           {errors.NewPassword?.type === "minLength" && (<p className="error" role="alert">min Length 8 characters </p>)}  
           <FaEye onClick={handlePasswordVisibility} />  
          </div>  
          <button type="submit">Send</button>  
        </div>  
       </form>  
      </div>  
      <div className="main">  
       <h1>New Admin</h1>  

       <form onSubmit={handleRegister}>  
        <div className="form">  
          <div className="input_form">  
           <label htmlFor="Name">Name</label>  
           <input  
            type="text"  
            name="Name"  
            value={name}  
            placeholder="Name"  
            required  
            onChange={(e) => setName(e.target.value)}  
           />  
          </div>  
          <br />
          <div className="input_form">  
           <label htmlFor="email">Email</label>  
           <input  
            type="email"  
            name="Email"  
            value={email}  
            onChange={(e) => setEmail(e.target.value)}  
            placeholder="Email"  
            required  
           />  
          </div>  
          <div className="input_form password">  
           <label htmlFor="password">Password</label>  
           <input  
            type={showPassword ? 'text' : "password"}  
            value={password}  
            onChange={(e) => setPassword(e.target.value)}  
            autoComplete="new-password"  
            name="Password"  
            placeholder="Password"  
            required  
           />  
           <FaEye onClick={handlePasswordVisibility} />  
          </div>  
          {dataError && <p className="error_email">{dataError}</p>}  
          <button type="submit">Send</button>  
        </div>  
       </form>  
      </div>  
    </div>  
    <div className='container'>  
      <table className="table" border={1}>  
       <thead className='thead-light'>  
        <tr>  
          <th scope="col">#</th>  
          <th scope="col">Name</th>  
          <th scope="col">Email</th>  
          <th scope="col">Delete</th>  
        </tr>  
       </thead>  
       <tbody>  
        {  
          data.map((item, index) => {  
           return (  
            <tr key={index} style={{ height: '-5px' }}>  
              <td>{item.id}</td>  
              <td>{item.name}</td>  
              <td>{item.email}</td>  
              <td><button onClick={() => handleDelete(item.id)}>Delete</button></td>  
            </tr>  
           )  
          })  
        }  
       </tbody>  
      </table>  
    </div>  
    <Dialog  
      open={open}  
      onClose={handleCancelDelete}  
      aria-labelledby="alert-dialog-title"  
      aria-describedby="alert-dialog-description"  
    >  
      <DialogTitle id="alert-dialog-title">  
       {"Confirm Delete"}  
      </DialogTitle>  
      <DialogContent>  
       <DialogContentText id="alert-dialog-description">  
        Are you sure you want to delete this user?  
       </DialogContentText>  
      </DialogContent>  
      <DialogActions>  
       <Button onClick={handleCancelDelete}>Cancel</Button>  
       <Button onClick={handleConfirmDelete} autoFocus>  
        Delete  
       </Button>  
      </DialogActions>  
    </Dialog>  
    <br />  
    <br />  
    <br />  
   </div>  
  );  
};  
  
export default Profile;
