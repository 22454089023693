import React from "react";
import './Media.css';
import { useEffect, useState } from "react";
import Nav from "../nav_bar/Nav_bar";
import Site from "../side_bar/side_bar";

import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from "../../axios/http";
import CheckAdmin from "../CheckAdmin";
import { useNavigate } from "react-router-dom";

const Media=(props)=>{
    const navigate = useNavigate();

    useEffect(() => {
    CheckAdmin()===false ? navigate('/Admin') : console.log();

        http.get('Media/Get').then(
            res=>{                
                setValue('facebook',res.data.facebook)
                setValue('telegram',res.data.telegram)
                setValue('instagram',res.data.instagram)
                setValue('whats',res.data.whats)                
                setValue('map',res.data.map)                
            }
        )

    }, []);

     const {
        register,
        handleSubmit,
        setError,
        setValue ,
        formState: { errors},
      } = useForm({
        defaultValues:{
            
            facebook: '',
            telegram: '',
            instagram: '',
            whats: '',
            map:''
 
        }
    });
    // =======================================
    const [loading, setloading] = useState(false);


    
    
    const  handel_submit= (data)=>{
        setloading(true)

        http.post('Media/Update',data).then(res=>{
            res.status==200 ? toast.success("New Media added 👍",{autoClose: 2000,}) : toast.error("ERROR ",{autoClose: 2000,})
            setloading(false)            
// =======================Reset data============================================
        setValue('facebook','')
        setValue('telegram','')
        setValue('instagram','')
        setValue('whats','')
        setValue('map','')
      
        setloading(false)

// =======================Reset data============================================

        }).catch(error=>{
            const errors=error.response.data.errors;
            Object.keys(errors).forEach(key => {
                setError(key, {type: "Error",message: errors[key][0],})    
            });

        })
    }

    return(
        <div className={`Media ${props.color==false ? 'dark': ''}` }>
                    <ToastContainer />

            <Site active='media' color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
            <div className="contener" id="body">
                <Nav color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
                <div className="top">
                    <h1>add  Media</h1>
                </div>
                <div className="bottom">
              
                    <div className="right">
                        <form onSubmit={handleSubmit(handel_submit)}>
                            <div className="form">
                                <div className="input_form">
                                    <label>facebook</label>
                                    <input type="url"  {...register("facebook")}  placeholder="facebook"/>
                                    {errors.facebook && <p className="error">{errors.facebook.message}</p>}

                                </div>
                                <div className="input_form">
                                    <label>telegram</label>
                                    <input type="url" {...register("telegram")} placeholder="telegram"/>
                                    {errors.telegram && <p className="error">{errors.telegram.message}</p>}
                                
                                </div>

                                <div className="input_form">
                                    <label>instagram</label>
                                    <input type="url"  {...register("instagram")}  placeholder="instagram"/>
                                    {errors.instagram && <p className="error">{errors.instagram.message}</p>}

                                </div>
                                <div className="input_form">
                                    <label>whats</label>
                                    <input type="url" {...register("whats")} placeholder="whats"/>
                                    {errors.whats && <p className="error">{errors.whats.message}</p>}
                                
                                </div>

                                <div className="input_form map">
                                    <label>map</label>
                                    <textarea type="url" {...register("map")} placeholder="map"/>
                                    {errors.map && <p className="error">{errors.map.message}</p>}
                                
                                </div>

                                

                            </div>
                            


                            <div className="btn">
                            <button  disabled={loading ? true : false}>sent</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Media;