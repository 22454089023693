import React from "react";
import Nav from "./../nav_bar/Nav_bar";
import Site from "./../side_bar/side_bar";
import "./About.css";
import {VscEye} from "react-icons/vsc";
import {ImUpload} from "react-icons/im";
import { useEffect, useState } from "react";
import http from "../../axios/http";

import avatar from "./avtart.jpg";
import { useNavigate } from "react-router-dom";
import CheckAdmin from "../CheckAdmin";
const About=(props)=>{
    useEffect(() => {
        CheckAdmin()===false ? navigate('/Admin') : console.log();

        http.get('About/Get').then(
            res=>{
                setHead(res.data.title);
                setText(res.data.text);
                setHead_ar(res.data.title_ar);
                setText_ar(res.data.text_ar);
                setVideo(res.data.image)
            }
            
        )
    }, []);
    const [Data, setData] = useState({});
    let navigate = useNavigate();
    const [file, setfile] = useState('');
    const [Head, setHead] = useState('');
    const [Text, setText] = useState('');
    const [Head_ar, setHead_ar] = useState('');
    const [Text_ar, setText_ar] = useState('');
    const [Video, setVideo] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        document.getElementById('sent').disabled=true;
        http.post('About/Update',{
            image:file,
            title:Head,
            text:Text,
            title_ar:Head_ar,
            text_ar:Text_ar,
        }).then(

            navigate('/')
        )
        // ... do something with email
    }
    return(
        <div className={`About ${props.color ===false ? 'dark' :''}`}>
            <Site active='About' color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
            <div className="contener" id="body">
                <Nav color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
                <div className="top">
                    <h1>About</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        <video src={Video} alt=""  autoplay  muted loop></video>
                    </div>
                    <div className="right">
                        <form  onSubmit={handleSubmit}>
                            <div className="form">
                                <div className="input_form">
                                    <label htmlFor="file" className="label_file"> image: <ImUpload className="upload"/></label>
                                    <input type='file'   id="file" onChange={e=>setfile(e.target.files[0])} style={{display:"none"}}/>
                                </div>
                                <div className="f"> 
                                <div className="input_form Head">
                                    <label>Head</label>
                                    <input type="text" value={Head} required name="head" onChange={e=>setHead(e.target.value)} placeholder="Head"/>
                                </div>
                                <div className="input_form Head ar">
                                    <label>العنوان</label>
                                    <input type="text" value={Head_ar} required name="head_ar" onChange={e=>setHead_ar(e.target.value)} placeholder="العنوان"/>
                                </div>


                                </div>
                                <div className="input_form">
                                    <label>Text</label>
                                    <textarea  value={Text} required name="text" onChange={e=>setText(e.target.value)}  placeholder="Text"/>
                                </div>
                                

                                <div className="input_form ar">
                                    <label>النص</label>
                                    <textarea  value={Text_ar} required name="text_ar" onChange={e=>setText_ar(e.target.value)}  placeholder="النص"/>
                                </div>
                                

                                <div className="button">

                                <button id="sent" >sent</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About;