import React, { useEffect, useState } from "react";  
import { DataGrid } from '@mui/x-data-grid';  
import Nav_bar from "../nav_bar/Nav_bar";  
import Side_bar from "../side_bar/side_bar";  
import "./Project.css"  
import { Link, useNavigate } from "react-router-dom";  
import http from "../../axios/http";  
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';  
import CheckAdmin from "../CheckAdmin";
  
const Project = (props) => {  
       const navigate = useNavigate();
   
   useEffect(() => {  
    CheckAdmin()===false ? navigate('/Admin') : console.log();

      http.get('Project/Get').then(res => {  
        setDATA(res.data);  
      })  
   }, []);  
  
   const [data, setDATA] = useState([]);  
   const [open, setOpen] = useState(false);  
   const [deleteId, setDeleteId] = useState(null);  
   const [selectionModel, setSelectionModel] = useState([]);  
  
   const handleDelete = (e) => {  
      setDeleteId(e);  
      setOpen(true);  
   }  
  
   const handleConfirmDelete = () => {  
      if (selectionModel.length > 0) {  
        http.post('Project/DeleteMultiple', {  
           'ids[]': selectionModel,  
        }).then(  
           res => {  
              console.log(res);  
           }  
        )  
        setDATA(data.filter((item) => !selectionModel.includes(item.id)));  
      } else {  
        http.post('Project/Delete', {  
           id: deleteId,  
        }).then(  
           res => {  
              console.log(res);  
           }  
        )  
        setDATA(data.filter((item) => item.id !== deleteId));  
      }  
      setOpen(false);  
   }  
  
   const handleCancelDelete = () => {  
      setOpen(false);  
   }  
  
   const handleDeleteMultiple = () => {  
      if (selectionModel.length > 0) {  
        setOpen(true);  
      }  
   }  
  
   const columns = [  
      { field: 'id', headerName: 'ID', width: 70 },  
      { field: 'image', headerName: 'Image', width: 200, renderCell: (data) => {  
        return (  
           <div className="img_name">  
              <img src={data.row.images[0].image} alt="" />  
              <span>{data.row.name}</span>  
           </div>  
        )  
      }  
      },  
      { field: 'app_store', headerName: 'App Store', width: 200 },  
      { field: 'play_store', headerName: 'Play Store', width: 200 },  
      { field: 'website', headerName: 'Website', width: 200 },  
      { field: 'text', headerName: 'Text', width: 500 },  
  
      {  
        field: 'action', headerName: "Action", width: 200,  
        renderCell: (props) => {  
           return (  
              <div className="action">  
                <button><Link to={`/Edit-Project/${props.id}`}>Show</Link></button>  
                <button key={props.id} onClick={() => handleDelete(props.id)}>Delete</button>  
              </div>  
           )  
        }  
      }  
   ];  
  
   return (  
      <div className={`main_users ${props.color == false ? 'dark' : ''}`} id="body">  
        <Side_bar active='Project' color={props.color} handel_color={props.handel_color} handel_side={props.handel_side} />  
        <div className="nav contener user_list_" id="body">  
           <Nav_bar color={props.color} handel_color={props.handel_color} handel_side={props.handel_side} />  
           <div className={`table ${props.color == false ? 'dark' : ''}`} id="body">  
              <div className="add_new_user">  
                <span className="title">add new Project</span>  
                <span><Link to="/New_Project">add new</Link></span>  
              </div>  
              <Button variant="contained" color="error" onClick={handleDeleteMultiple}>  
                Delete Selected  
              </Button>  
              <DataGrid  
                rows={data}  
                columns={columns}  
                pageSize={8}  
                rowsPerPageOptions={[2]}  
                checkboxSelection  
                onSelectionModelChange={(newSelectionModel) => {  
                   setSelectionModel(newSelectionModel);  
                }}  
                selectionModel={selectionModel}  
              />  
           </div>  
        </div>  
        <Dialog  
           open={open}  
           onClose={handleCancelDelete}  
           aria-labelledby="alert-dialog-title"  
           aria-describedby="alert-dialog-description"  
        >  
           <DialogTitle id="alert-dialog-title">  
              {"Confirm Delete"}  
           </DialogTitle>  
           <DialogContent>  
              <DialogContentText id="alert-dialog-description">  
                Are you sure you want to delete this project?  
              </DialogContentText>  
           </DialogContent>  
           <DialogActions>  
              <Button onClick={handleCancelDelete}>Cancel</Button>  
              <Button onClick={handleConfirmDelete} autoFocus>  
                Delete  
              </Button>  
           </DialogActions>  
        </Dialog>  
      </div>  
   )  
}

export default Project;